<template>
  <div class="landing-page-forgot">
    <nav>
      <div class="container nav-mdxl">
        <router-link to="/guest/welcome" class="logo-mdxl">
          <img src="@/assets/images/landing-page/logo_mdxl.png" alt="logo mdxl" />
        </router-link>
        <!-- <div @click="openModalLogin()" class="btn btn-sm btn-login">
          <i class="fa fa-user pull-left" title="Note" aria-hidden="true"></i> Login
        </div> -->
      </div>
    </nav>
    <div class="container">
      <div class="content">
        <div class="content__information">
          <a-card
            class="card"
            v-if="state.requestOtp"
            :title="null"
          >
            <div class="title-forgot">
              <label for="Lupa Password">Lupa Password</label>
            </div>
            <a-form
              ref="formOtp"
              class="myform form-forgot-password"
              layout="vertical"
              :rules="rules"
              :model="loginForm"
            >
              <a-form-item has-feedback name="identity" class="email">
                <a-input v-model:value="loginForm.identity" placeholder="user@gmail.com or username">
                  <template #prefix>
                    <img src="@/assets/images/icon/ic_person.svg" alt="logo mdxl" />
                  </template>
                </a-input>
                <div class="danger-info">
                  <span>*</span> Username dan email yang terdaftar pada AksesToko
                </div>
              </a-form-item>
              <a-form-item>
                <button
                  type="submit"
                  class="btn btn-submit w-100"
                  @click="requestOtp"
                  :loading="loading"
                >
                  Minta OTP
                </button>
              </a-form-item>
            </a-form>

            <div v-if="state.errors">
                <ASpace>
                    <AAlert
                        v-for="(item, index) in state.errors"
                        type="error"
                        :key="index"
                        :message="item.message"
                        banner
                        closable
                        @close="state.errors = []" />
                </ASpace>
            </div>
          </a-card>
    
          <a-card class="card" v-if="showResetPassword">
            <div class="title-forgot">
              <label for="Lupa Password">Lupa Password</label>
            </div>
            <a-form
              class="myform form-forgot-password"
              layout="vertical"
              :model="loginForm"
              :rules="rules"
              ref="formOtp"
            >
              <p class="info-otp">
                OTP sudah dikirimkan ke email yang terdaftar pada akun
                <b>{{ loginForm.identity }}</b> Masukkan OTP dan Password Baru
              </p>
              <a-form-item>
                <a-input v-model:value="loginForm.otp" placeholder="OTP" autocomplete="off">
                  <template #prefix>
                    <img src="@/assets/images/icon/ic_key.svg" alt="logo mdxl" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item class="password">
                <a-input-password
                  v-model:value="loginForm.password"
                  placeholder="Kata Sandi Baru"
                  autocomplete="new-password"
                >
                  <template #prefix>
                    <img src="@/assets/images/icon/ic_lock.svg" alt="logo mdxl" />
                  </template>
                </a-input-password>
              </a-form-item>
              <a-form-item>
                <a-input-password
                  v-model:value="loginForm.retype_password"
                  placeholder="Ulangi Kata Sandi Baru"
                  autocomplete="new-password"
                >
                  <template #prefix>
                    <img src="@/assets/images/icon/ic_lock.svg" alt="logo mdxl" />
                  </template>
                </a-input-password>
              </a-form-item>
              <a-form-item name="submit">
                <button
                  type="submit"
                  class="btn btn-submit w-100"
                  @click="resetPassword"
                  :loading="loading"
                >
                  Submit
                </button>
                <!-- <a-button class="ml-4" @click="resetForm">Batal</a-button> -->
              </a-form-item>
            </a-form>

            <div v-if="state.errors">
                <ASpace>
                    <AAlert
                        v-for="(item, index) in state.errors"
                        type="error"
                        :key="index"
                        :message="item.message"
                        banner
                        closable
                        @close="state.errors = []" />
                </ASpace>
            </div>
          </a-card>
    
          <a-card class="card card-success" v-if="state.finish" title="Reset Password">
            <label>Kata sandi berhasil diubah</label>
            <div class="images-success">
              <img src="@/assets/images/landing-page/mdxl_img_success.png" alt="success mdxl" />
            </div>
            <button type="submit" class="btn btn-submit w-100" @click="openModalLogin()">
              Login
            </button>
          </a-card>
        </div>
      </div>
    </div>
    <modal-login />
  </div>
</template>
<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import ModalLogin from '../../landing-page/ModalLogin.vue'

export default {
  name: 'VbLogin',
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)
    const loading = ref(false)
    const formOtp = ref()
    const openModalLogin = () => {
      store.getters.settings.isModalLogin = true
    }
    const rules = {
      identity: [
        {
          required: true,
          message: 'Please input your username or email!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Please input password!', trigger: 'change' }],
    }

    const loginForm = reactive({
      password: null,
      retype_password: null,
      identity: null,
    })

    const state = reactive({
      requestOtp: true,
      resetPassword: false,
      finish: false,
      errors: [],
    })

    const requestOtp = () => {
      formOtp.value.validate().then(() => {
        loading.value = true
        apiClient
          .post('/api/auth/otp-request', loginForm)
          .then(response => {
            state.requestOtp = false
            state.resetPassword = true
            message.success('Permintaan OTP berhasil dikirim')
          })
          .catch(({ response: { data } }) => {
            state.errors = data
          })
          .finally(() => {
            loading.value = false
          })
      })
    }
    const showResetPassword = computed(() => {
      return !state.requestOtp && loginForm.identity && state.resetPassword
    })
    const resetPassword = () => {
      // apiClient.post('/api/auth/reset-password', loginForm)
      loading.value = true
      apiClient
        .post('/api/auth/reset-password', loginForm)
        .then(response => {
          state.requestOtp = false
          state.resetPassword = false
          state.finish = true
          loginForm.identity = null
          loginForm.password = null
          loginForm.retype_password = null
          message.success('Reset Password berhasil dilakukan')
        })
        .catch(({ response: { data } }) => {
          state.errors = data
        })
        .finally(() => {
          loading.value = false
        })
    }

    const resetForm = () => {
      state.requestOtp = true
      state.resetPassword = false
      state.finish = false
      loginForm.identity = null
      loginForm.password = null
      loginForm.retype_password = null
    }

    return {
      settings,
      loading,
      rules,
      loginForm,
      requestOtp,
      resetPassword,
      state,
      resetForm,
      formOtp,
      showResetPassword,
      ModalLogin,
      openModalLogin,
    }
  },
}
</script>
<style lang="scss" scoped>
@import './style/scss/variables.scss';
@import './style/scss/index.scss';

.navbar {
  background-color: #630700;
  height: 3.5rem;
  padding: 2.3rem 0;
  margin-bottom: 0.3rem;
}

.imgtop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.card {
  position: relative;
  margin: 0 auto;
  width: 28rem;
  border: 2px solid #630700;
  border-radius: 18px;
}

.card img {
  /* border: 1px solid red; */
  margin: 0 auto;
  align-items: center;
  display: block;
  width: 100%;
}

@media only screen and (max-width: 300px) {
  .card {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }

  .txt {
    font-size: 0.9rem;
  }
}
</style>

<style>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
</style>
